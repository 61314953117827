import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    totalNum:0,
    schoolId:''
  },
  mutations: {
    totalNum(state,value){
      state.totalNum = value;
    },
    schoolId(state,value){
      state.schoolId = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
