 <template>
  <div class="content-left1">
    <div class="leftop">
      <div class="title-bg">活动分类平均学时</div>
      <hoursChart />
    </div>
    <div class="leftop">
      <div class="title-bg">荣誉分类TOP5</div>
      <prizeChart />
    </div>
    <div class="leftop">
      <div class="title-bg">校园组织统计</div>
      <organizeChart />
    </div>
  </div>
</template>
<script>
import hoursChart from "./right/hoursChart";
// import prizeChart from './left/prizeChart';
// import activateChart from './left/activateChart';
import prizeChart from "./left/prizeChart";
import organizeChart from "./right/organizeChart";

export default {
  components: {
    hoursChart,
    organizeChart,

    prizeChart,
  },
  data() {
    return {};
  },
};
</script>
<style>
.content-left1 {
  width: 100%;
}
.leftop {
   height:29.3vh;
  background: url(../assets/back.png) no-repeat;
  background-size: 100% 100%;
}
.prize-activate {
  display: flex;
  margin-top: 0px;
}
.prize,
.activate {
  width: 48%;
}
.activate {
  padding-left: 4%;
}
.student-number {
  width: 100%;
}
.student-number-content {
  display: flex;
}
.student-number-header {
  width: 87%;
  display: flex;
  justify-content: space-between;
  padding: 0 5vh;
  line-height: 5.5vh;
}
.title-bg {
  color: #01e2ff;
  box-sizing: border-box;
  padding: 0 30px;
  font-weight: 700;
}
.label-column div {
  color: #6f808e;
}
.unit-label div {
  color: #6f808e;
}
.student-number-header {
  color: #647686 !important;
}
.student-number-header span {
  color: white !important;
  font-weight: 700;
}
.text-center {
  color: #647686 !important;
  font-size: 14px;
}
.pie {
  height: 200px;
  width: 200px;
}
.line {
  height: 200px;
  width: 300px;
}
</style>
