<template>
  <div style="display: flex; flex-direction: row; height:89%;position: relative;">
    <div
      id="myChart4"
      class="echart"
      style="width:100%; height:100%;position: absolute;    
      
      "
    ></div>
    <div v-if="!data.length" class="no-data" style="position: relative;
    right:2.5em;">
        <img src="@/assets/backnone.png" alt="">
      </div>
    <div class="cont-count" style="right: 0;
    position: absolute;">
      <p v-for="(item, index) in data" :key="index">
        <span v-if="index<7">
          {{
            item.classifyName.length > 5
              ? item.classifyName.substr(0, 5) + "..."
              : item.classifyName
          }}<span class="company"> {{ item.count | numFilter }} %</span>
        </span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "hello",
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后2位
      if(value&&(value!=null||value!='null')){
      let tempVal = parseFloat(value*1).toFixed(2);
      return tempVal;
      }else{
        return 0
      }
    },
    formatter: (val) => {
      let txt = val;
      if (val.length > 5) {
        txt = val.substr(0, 4) + "...";
      }
      return txt;
    },
  },
  data() {
    return {
      dateInfo: [],
      countInfo: [],
      screenWidth:'',
      data: [],
    };
  },
  created() {
    this.screenWidth=window.screen.width
    console.log(this.screenWidth,":window.screen.width")
    let that = this;
    this.getInfo();
    setInterval(function () {
      that.getInfo();
    }, 60000);
  },

  methods: {
    getInfo() {
      let that = this;
      this.$utils
        .ajax("v1/school-user/activity-score-statistics")
        .then((res) => {
          if (res.status == 200) {
            that.dateInfo = [];
            that.data = [];
            that.countInfo = [];
            that.data = res.data
            console.log(res.data, "res.data");
            let countArr = [];
            let maxNum = "";
            that.data.forEach((item) => {
              countArr.push(Number(item.count));
            });
            maxNum = countArr.sort((num1, num2) => {
              return num2 - num1;
            });

            console.log(maxNum, "maxNum");           
            
            that.data.forEach((item, index) => {
              if (index < 8) {
                that.dateInfo.push({
                  name:item.classifyName.length > 6
                       ? item.classifyName.substr(0, 6) + "..."
                     : item.classifyName,               
                  max: maxNum[0],
                });
                that.countInfo.push(Number(item.count));
              }
            });
  // item.classifyName.length > 5
                    //   ? item.classifyName.substr(0, 5) + "..."
                    //   : item.classifyName,
            console.log(that.dateInfo, that.countInfo, "活动分类平均学时");
            that.loadLeida();
          }
        });
    },

    loadLeida() {
      let option = {
        // title: {
        //   text: '基础雷达图'
        // },
        tooltip: {},
        legend: {
          right: 50,
          data: ["当前部门", "集团大盘"],
        },  
         textStyle: {
                        color: ["#CCCCCC"],
                        fontSize: 11,
                        margin: [
                            1,  // 上
                            10, // 右
                            5,  // 下
                            0, // 左
                        ]
                    },
                    grid: { // 控制图的大小，调整下面这些值就可以，
                        x: 45,
                        x2: 100,
                        y2: 100// y2可以控制 X轴跟Zoom控件之间的间隔，避免以为倾斜后造成 label重叠到zoom上
                    },      
        radar: {
          radius:window.screen.width>1699?61:44,
            center: ["34%", "50%"],
          // shape: 'circle',
          name: {
            textStyle: {
              color: "#f5f5f5",
              borderRadius: 0,
            },             
          },

          axisLine: {
            lineStyle: {
              color: "rgba(131,141,158,.8)",
            },
          },
          indicator: this.dateInfo,
          splitArea: {
            show: false,
            areaStyle: {
              color: "rgba(255,0,0,0)", // 图表背景的颜色
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              color: "#a5a9ac", // 设置网格的颜色
            },
          },
        },
        series: [
          {
            name: "雷达图",
            type: "radar",
            symbol: "none", // 拐点的样式，还可以取值'rect','angle'等
            symbolSize: 8,
            itemStyle: {
              normal: {
                formatter: (val) => {
                  let txt = val;
                  if (val.length > 6) {
                    txt = val.substr(0, 5) + "...";
                  }
                  return txt;
                },
                label: { show: true },
                color: "rgba(0,195,187)",
                lineStyle: {
                  width: 1,
                },
              },
            },
            areaStyle: {
              normal: {
                color: "rgba(0,195,187,.8)",
              },
            },
            data: [
              {
                value: this.countInfo,
              },
            ],
          },
        ],
      };
      this.myChartOne = this.$echarts.init(document.getElementById("myChart4"));
      this.myChartOne.setOption(option);
    },
  },
};
</script>
<style scoped>
.hours-dv-chart {
  width: 100%;
  /* height: 22vh; */
}
.cont-count {
  width: 35%;
  float: right;
  margin-left:4%;
  padding-top: 20px;
}
.cont-count p {
  font-size: 13px;
}
.cont-count .company {
  color: #df0005;
}
.no-data { 
  height: 220px;
 display: flex;
    align-items: center;
    justify-content: center;
}
</style>



 
 
 









