<template>
  <div id="myChart1" :style="{ width: '100%', height: '88%' }"></div>
</template>
<script>
export default {
  name: "hello",
  data() {
    return {
     dateInfo:['思想素养', '创新创业', '特长技能', '社会实践', '志愿公益', '文化互动', '成长履历'],
      countInfo: [0,0,0,0,0,0,0],
    };
  },
  created() {
    let that = this;
    this.getInfo();
    setInterval(function () {
      that.getInfo();
    }, 60000);
  },
  methods: {
    getInfo() {
      let that = this;
      this.$utils.ajax("v1/activity/activity-class-statistics").then((res) => {
        if (res.status == 200) {
          if(res.data.length!=0){
          that.dateInfo = [];
          that.countInfo = [];
          res.data.forEach((item, index) => {
            if (index < 7) {
              that.dateInfo.push(item.name);
              that.countInfo.push((item.value * 100).toFixed(2));
            }
          });
        }
          console.log(that.dateInfo, "that.dateInfo ");
          console.log(that.countInfo, "that.countInfo ");
          that.drawLine();
        }
      });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart1"));
      // 绘制图表
      myChart.setOption({
        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(168, 231, 88,.0)",
          },
          {
            offset: 0.5,
           color: "rgba(168, 231, 88,.5)",
          },
          {
            offset: 1,
            color: "rgba(168, 231, 88,.9)",
          },
        ]),
        tooltip: {
          //显示提示
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          top: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          splitLine: { show: false }, //关闭网格线
          axisLine: {
            //x轴坐标颜色
            lineStyle: {
              color: "#d5d4d9",
            },
          },
          axisTick: {
            alignWithLabel: true,
          },

          axisLabel: {
            formatter: (val) => {
              let txt = val;
              if (val.length > 6) {
                txt = val.substr(0, 5) + "...";
              }
              return txt;
            },
          },
          data: this.dateInfo,
        },
        yAxis: {
          splitLine: { show: false },
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,.5)",
            },
          },
          type: "value",
        },
        series: [
          {
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    //数值样式
                    color: "#dfdfe1",
                    fontSize: 12,
                  },
                },
              },
            },
            name: "",
            type: "bar",
            smooth: true,
            barCategoryGap: "70%",
            areaStyle: {},
            data: this.countInfo,
          },
        ],
      });
    },
  },
};
</script>
<style scoped>
#myChart1 {
  margin-top: 10px;
}
#myChart1 > div {
  height: 88%;
  margin: 1vh 0;
  color: #01d1ff !important;
}
#myChart1 > div > canvas {
  height: 88%;
  color: #01d1ff;
}
</style>