<template>
  <div>
    <dv-border-box-10  style="margin-top:6px">
      <div class="center-title" style="width: 100%">
        <div>
          <span style="color: #ccc"><span class="color1">学生人数</span></span>
          <span>
            {{ $store.state.totalNum }}<span class="font12">人</span>
          </span>
        </div>
        <div>
          <span style="color: #ccc"
            ><span class="color1">累计活动数</span></span
          >
          <span> {{ activityNum }}<span class="font12">个</span></span>
        </div>
        <div>
          <span style="color: #ccc"
            ><span class="color1">参与活动人次</span></span
          >
          <span> {{ totalNum }}<span class="font12">次</span> </span>
        </div>
        <div>
          <span style="color: #ccc"
            ><span class="color1">奖项申报人次</span></span
          >
          <span> {{ participateProject }}<span class="font12">次</span> </span>
        </div>
      </div>
    </dv-border-box-10>
    <!-- <div><span style="color:#ccc;"><span class="color1">团支部个数</span></span><br/>{{branchNum}}<span class="font12">个</span></div> -->

    <dv-border-box-13 id="topcenter">
      <div class="title-bg" style="width: 275px">院系发布TOP10</div>
      <dv-scroll-ranking-board
        v-if="config.data && config.data.length > 0"
        class="m-top10"
        :config="config"
        style="width: 90%; height: 28vh; margin: 1.5vh auto;"
      />
      <div v-else class="no-data">
        <img src="../assets/backnone.png" alt="">
      </div>
    </dv-border-box-13>

    <div class="m-top20">
      <div class="title-bg" style="width: 275px">实时活动</div>
      <dv-scroll-board
        v-if="config1.data && config1.data.length > 0"
        class="m-top10 leftback"
        :config="config1"
        style="width: 100%; height: 35vh"
      />
      <div v-else class="no-data"  style="height: 35vh" > <img src="../assets/backnone.png" alt=""></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      config: {},
      config1: {},
      data: {
        rowNum: [6],
        waitTime: 2300,
        columnWidth: [50],
        data: [],
        unit: "个",
        carousel: "single",
      },
      data1: {
        header: ["活动名称", "参与人数", "主办单位"],
        data: [],
        rowNum: [7],
        index: true,
        indexHeader: "序号",
        waitTime: 1000,
        columnWidth: [50],
        align: ["center"],
        carousel: "single",
      },
      peopleNum: 0,
      branchNum: 0,
      activityNum: 0,
      totalNum: 0,
      participateProject: 0,
    };
  },
  created() {
    let that = this;
    this.getInfo();
    this.getInfo1();
    this.peopleInfo();
    this.branchInfo();
    this.activityInfo();
    this.totalInfo();
    this.projectTotalInfo();
    setInterval(function () {
      that.getInfo();
      that.getInfo1();
      that.peopleInfo();
      that.branchInfo();
      that.activityInfo();
      that.totalInfo();
      this.projectTotalInfo();
    }, 60000);
  },
  mounted() {
    document.get;
    var n = document.getElementsByClassName("header");
    console.log(n, "获取节点");
  },
  methods: {   
    projectTotalInfo() {
      let that = this;
      this.$utils
        .ajax("v1/school-user/participate-project-statistics")
        .then((res) => {
          if (res.status == 200) {
            that.participateProject = res.data[0].participateProject;
          }
        });
    },
    getInfo() {
      let that = this;
      this.$utils
        .ajax("v1/activity/college-activity-statistics")
        .then((res) => {
          if (res.status == 200) {
            let data = that.data;
            data.data = [];
            res.data.forEach((item) => {
              data.data.push({
                name: '<span style="color:#d5900f;">' + item.name + "</span>",
                value: item.activityCount,
              });
            });
            this.config = data;
            console.log(res.data, "sasas");
          }
        });
    },
    getInfo1() {
      let that = this;
      this.$utils
        .ajax("v1/activity/activity-dynamic-statistics")
        .then((res) => {
          if (res.status == 200) {
            let data = that.data1;
            data.data = [];
            res.data.forEach((item) => {
              data.data.push([
                item.activityName,
                item.enrollNumber,
                item.collegeName,
              ]);
            });
            that.config1 = data;
            console.log(that.config1, " that.config1");
          }
        });
    },
    peopleInfo() {
      let that = this;
      this.$utils
        .ajax("v1/school-user/league-members-statistics")
        .then((res) => {
          if (res.status == 200) {
            that.peopleNum = res.data[0].leagueMemberCount;
          }
        });
    },
    branchInfo() {
      let that = this;
      this.$utils
        .ajax("v1/school-organization/league-branch-statistics")
        .then((res) => {
          if (res.status == 200) {
            that.branchNum = res.data[0].leagueBranchCount;
          }
        });
    },
    activityInfo() {
      let that = this;
      this.$utils.ajax("v1/activity/activity-number-statistics").then((res) => {
        if (res.status == 200) {
          that.activityNum = res.data[0].activityNumber;
        }
      });
    },
    totalInfo() {
      let that = this;
      this.$utils
        .ajax("v1/school-user/participate-activity-statistics")
        .then((res) => {
          if (res.status == 200) {
            that.totalNum = res.data[0].participateActivity;
          }
        });
    },
  },
};
</script>
<style>
.dv-scroll-board .header {
  background-size: 100% 100%;
  height: 5.3vh;
}
.m-top20 .dv-scroll-ranking-board .ranking-column {
}
.m-top20 .dv-scroll-ranking-board .ranking-column .inside-column {
  /* background-color: #f39801;*/
  height: 0px;
}

.m-top20 .ranking-value {
  display: none;
}
.m-top20 .row-item .rank {
  color: #cad1d6 !important;
  font-weight: 700;
}

.m-top20 .row-item .info-name {
  font-weight: 700;
  color: #cad1d6;
}
.m-top20 .ceil:nth-child(1) .index {
  color: white;
}
.m-top20 .ceil {
  color: #eaeceb;
  text-align: center;
}
.header-item {
  text-align: center;
}

.dv-scroll-board .rows .row-item {
  box-sizing: border-box;
  width: 100%;
  height: 32 !important;
  line-height: 35px !important;   
  border-bottom: 0.5px solid #005399;
  background-color: rgba(0, 186, 255, 0) !important;
}

.dv-scroll-board .rows .row-item .ceil {
  box-sizing: border-box;
}
.dv-scroll-board .rows .row-item .ceil:first-child .index {
  background-color: rgba(0, 186, 255, 0) !important;
}
.dv-scroll-board .header {
  background-color: rgba(0, 186, 255, 0) !important;
}
.leftback {
  width: 100%;
  overflow: hidden;
  background: url(../assets/back@2.png) no-repeat;
  height: 31vh;
  background-size: 100% 100%;
}
.m-top20 .title-bg {
  color: #01e2ff;
  box-sizing: border-box;
  padding: 0 30px;
  font-weight: 700px;
}
.center-title {
  display: flex;
  justify-content: space-around;
  line-height: 39px;
  font-size: 18px;
  background-size: 100% 100%;
}
.no-data { 
  height: 220px;
 display: flex;
    align-items: center;
    justify-content: center;
}
.reset-line > div.row-item {
  height: 28px !important;
}

.center-title span {
  box-sizing: border-box;
  text-align: center;
  display: block;
  padding: 0;
}
.center-title .font12 {
  display: inline;
  margin-left: 4px;
}
.dv-border-box-10 .right-bottom {display:none !important;}
/* .dv-border-box-10 .left-top {display:none !important;} */
.dv-border-box-10 .left-bottom {display:none  !important;}
/* .dv-border-box-10 .right-top {display:none  !important;} */
 
</style>
