<template>
  <div id="app">
    <div class="loading-wrap" v-if="loadingStatus">
      <dv-loading>Loading...</dv-loading>
    </div>
    <dv-full-screen-container >
      <router-view/>
    </dv-full-screen-container>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loadingStatus:false
    }
  },
  created () {
    let that = this;
    setTimeout(function(){
      that.loadingStatus = false;
    },2000)
  }
}
</script>
<style>
@import "./static/base.css";
</style>