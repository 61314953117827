import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view'
import echarts from 'echarts'
import utils from './utils/utils';
Vue.prototype.$echarts = echarts
Vue.use(dataV)
Vue.use(utils)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
