<template>
  <div class="home">
    <div class="text-center home-title">
      <div>
        <img class="img" v-if="spiritLogo" :src="spiritLogo" alt="" />  
      </div>
      <div class="home-title-center">{{ schoolName }}</div>
      <div class="schoolMotto1">
        <p style="margin-right: 20px">{{ schoolMotto }}</p>
        <p class="timehour">
          <b>{{ nowTime }}</b>
          <span class="t2">{{ date }}</span>
        </p>
      </div>
    </div>
    <div class="home-content">
      <dv-decoration-10 style="width: 100%; height: 1px" />
      <div class="content-detail">
        <div class="content-left">
          <homeLeft />
        </div>
        <div class="content-center">
          <homeCenter />
          <p class="footer">{{ recordNumber }}</p>
        </div>
        <div class="content-right">
          <homeRight />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeLeft from "@/components/homeLeft";
import homeCenter from "@/components/homeCenter";
import homeRight from "@/components/homeRight";
export default {
  components: {
    homeLeft,
    homeCenter,
    homeRight,
  },
  data() {
    return {
      schoolName: "",
      spiritLogo: "",
      schoolMotto: "",
      recordNumber: "",
      nowTime: "",
      date: "",
    };
  },
  created() {
    if (
      this.$route.fullPath.split("?")[1] &&
      this.$route.fullPath.split("?")[1].split("=")[0] == "schoolId"
    ) {
      let schoolId = this.$route.fullPath.split("?")[1].split("=")[1];
      this.$store.commit("schoolId", schoolId);
    }
    this.getSchool();
  },
  mounted() {
    this.nowTimes();
  },
  methods: {
    timeFormate(timeStamp) {
      let year = new Date(timeStamp).getFullYear();
      let month =
        new Date(timeStamp).getMonth() + 1 < 10
          ? "0" + (new Date(timeStamp).getMonth() + 1)
          : new Date(timeStamp).getMonth() + 1;
      let date =
        new Date(timeStamp).getDate() < 10
          ? "0" + new Date(timeStamp).getDate()
          : new Date(timeStamp).getDate();
      let hh =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();
      let ss =
        new Date(timeStamp).getSeconds() < 10
          ? "0" + new Date(timeStamp).getSeconds()
          : new Date(timeStamp).getSeconds();
      this.date = year + "/" + month + "/" + date;
      this.nowTime = hh + ":" + mm + ":" + ss;
    },
    nowTimes() {
      this.timeFormate(new Date());
      setInterval(this.nowTimes, 1000);
      this.clear();
    },
    clear() {
      clearInterval(this.nowTimes);
      this.nowTimes = null;
    },
    getSchool() {
      let that = this;
      this.$utils.ajax("v1/school/info").then((res) => {
        if (res.status == 200) {
          console.log(res);
          that.schoolName = res.data[0].value;
          that.spiritLogo = res.data[0].spiritLogo;
          that.schoolMotto = res.data[0].schoolMotto;
          that.recordNumber = res.data[0].recordNumber;
          console.log(res.data[0]);
          console.log(this.spiritLogo);
        }
      });
    },
  },
};
</script>
<style>
.home {
  background: linear-gradient(to bottom, #040861, #03072a);
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}
.img {
    margin-right: 10px;
    margin-top: 0.13em;
    position: relative;
    top: 0.3em;
    margin-bottom: 0.1em;
    object-fit:contain;
    height: 45px;
    left:10px;
  /*  width: 9em; */
}
@media screen and (min-width:1601px) and (max-width: 1920px) {
	.img {
    margin-right: 10px;
    margin-top: 0.2em;   
    margin-bottom: 0.1em;
    object-fit:contain;
    height: 45px;
     left:10px;
 /*   width: 9em; */
}
}
.home-title {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  height:8%;
  font-weight: bold;
  font-size: 30px;
  background: url(../assets/head.png) no-repeat;
  background-size: 100% 100%;
}
.home-title div {
  width: 33.3%;
}
.home-title div:nth-child(1) {
  text-align: left;
}
.home-title div:nth-child(3) {
  text-align: right;
}
.home-title-center {
  display: flex;
  width: 40%;
  flex-direction: row;
  justify-content: center;
  color: #00ddf7;
  align-items: center;
}
.timehour {
  font-size: 17px;
  color: rgba(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.t2 {
  padding: 0;
  font-size: 14px;
  height: auto;
}
.home-content {
  width: 99%;
  padding: 0 0.5%;
   height:92%;
  /* height: 89.7vh; */
}
.schoolMotto1 {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.content-detail {
  display: flex;
  height:100%;
  justify-content: space-around;
}
.content-left {
  width: 30%;
}
.content-center {
  width: 40%;
}
.content-right {
  width: 30%;
}
.footer {
  color: rgba(255, 255, 255);
  opacity: 0.4;
  font-size: 12px;
  width: 100%;
  text-align: center;
}
</style>
