<template>
	<!-- <dv-capsule-chart class="line" :config="config" style="width:200px;height:200px" /> -->
	<div>
		<div class="dv-capsule-chart line" style="width:100%; height: 14vh;">
			<div class="label-column">
				<!--<div class="label-column-mark">级</div>-->
				<div v-for="(item,index) in grade" :key="index">{{item}}</div>				 
			</div>
			<div class="capsule-container">
				<div class="capsule-item" v-for="(item,index) in config" :key="index">
					<div :style="'width:'+(item.count/subMax)*100+'%; background:linear-gradient(90deg, #f39801,#f39801 '+(item.male/item.count)*100+'%,#d44d5e '+(item.male/item.count)*100+'%,#d44d5e );'"></div>
				</div>
				<!-- <div class="capsule-item">
					<div style="width: 40.1198%;"></div>
				</div>
				<div class="capsule-item"><div style="width: 73.6527%;"></div></div>
				<div class="capsule-item"><div style="width: 32.9341%;"></div></div>
				<div class="capsule-item"><div style="width: 58.6826%;"></div></div> -->
				<!--<div class="unit-label">
					<div class="unit-label-mark">人</div>
					<div v-for="(item,index) in subArr" :key="index">{{item}}</div>
				</div>-->
			</div>
			<!---->
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			config:[],	  
			grade:[],
			sub:[25,50,75,100],
			subMax:100,
			subArr:[]
		}
	},
	created () {
		let that = this;
		this.getInfo();
		setInterval(function(){
			that.getInfo();
		},60000)
	},
	methods: {
		getInfo(){
			let that = this;
			this.$utils.ajax('v1/school-user/user-grade-statistics').then((res)=>{
				console.log(res,"res")
				if(res.status == 200){
					let grade=[],subArr=[],info=[];
					res.data.forEach((item)=>{
						grade.push(item.grade);
					})
					grade = Array.from(new Set(grade))
					that.grade = grade;
					console.log(this.grade)
					grade.forEach((item,index)=>{
						subArr[index] = 0;
						info[index]={count:0,male:0,female:0}
						res.data.forEach((items)=>{
							if(items.grade == grade[index]){
								subArr[index] = subArr[index] + items.userCount;
								if(items.gender == 1){
									info[index].male = items.userCount
								}else{
									info[index].female = items.userCount
								}
							} 
						})
						info[index].count = subArr[index];
					})
					that.config =  info
					console.log(that.config)
					var max = subArr[0];
					console.log(info,"我是subArr")
					this.subArr=subArr
					for(var i=1;i<subArr.length;i++){ 
						if(max<subArr[i]) max=subArr[i];
					}
					this.subMax = max;
					this.handleSub(max);
				}
			})
		},
		handleSub(max){
			let sub = this.sub,z,y,handleSub=[];
			z=Math.floor(max/4);
			y=max % 4;
			sub.forEach((item,index)=>{
				if(index<y){
					handleSub.push((z+1) * [index+1]);
				}else{
					handleSub.push(z * [index+1] + y);
				}
			})
			this.sub = []
			this.sub = handleSub;
		},
		// grade(config){

		// }
	}
}
</script>
<style>
.dv-capsule-chart {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    color: #fff;
}
.dv-capsule-chart .label-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 10px;
    text-align: right;
	font-size: 12px;
	position: relative;
}
.label-column-mark {
	position: absolute;
	top: -3vh;
}
.dv-capsule-chart .label-column div {
    height: 23px;
    line-height: 20px;
}
.dv-capsule-chart .capsule-container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.dv-capsule-chart .capsule-item {
    -webkit-box-shadow: 0 0 3px #999;
    box-shadow: 0 0 3px #999;
    height: 10px;
    margin: 12px 0px !important;
    border-radius: 5px;
}
.dv-capsule-chart .capsule-item div {
    height: 8px;
    margin-top: 1px;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.dv-capsule-chart .unit-label {
    height: 20px;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
	justify-content: space-between;
	position: relative;
}
.unit-label-mark {
	position: absolute;
	right: -20px;
}
</style>