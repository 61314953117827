// import router from '@/router'
import axios from 'axios'
import config from './config'
import store from '../store'
const utils = {
    ajax(options, data, load, method){
        var that = this;
        that.url = options.url || options;
        that.method = options.method || (method || "GET");
        that.method = that.method.toUpperCase();
        that.contentType = options.contentType || 'application/x-www-form-urlencoded';
        that.type = options.type || 'json';
        that.data = options.data || (data || {});
        that.isLink = options.isLink ? options.isLink : false;     
        that.data.schoolId = store.state.schoolId;
        var configAxios = {
            method:that.method,
            url:that.isLink ? that.url : that.url,
            responseType: that.type,
            baseURL:config._API_,
            withCredentials:true
        };
        if(['PUT','POST','PATCH'].indexOf(that.method) != -1 && that.contentType == 'application/json'){
            configAxios.transformRequest = [function(data) {
                return JSON.stringify(data);
            }];
        }
        configAxios.headers = {
            'Content-Type': that.contentType
        };
        that.method == "GET" ? configAxios.params = that.data : configAxios.data = that.data;
        return axios(configAxios).then(function(res){
            if(res.status == 200){
                return res;
            }
        }).catch(function(){
            return {code:-1,reason:'网络请求或接口失败'};
        });
    },
};

export default {
    install(Vue) {
        Vue.prototype.$utils = utils;
    }
};