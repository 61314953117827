<template>
  <div id="organization" :style="{ width: '100%', height: '89%' }"></div>
</template>
<script>
export default {
  name: "hello",
  data() {
    return {
      dateInfo: ['社团协会', '学生会', '团组织', '班组织', '其他'],
      countInfo: [0,0,0,0,0],
    };
  },
  created() {
    let that = this;
    this.getInfo();
    setInterval(function () {
      that.getInfo();
    }, 60000);
  },
  methods: {
    getInfo() {
      let that = this;
      this.$utils
        .ajax("v1/school-organization/organization-class-statistics")
        .then((res) => {
          if (res.status == 200) {          
            console.log( res.data)
            if(res.data.length!=0){
            that.dateInfo = [];
            that.countInfo = [];
            res.data.forEach((item) => {
            console.log(item)
              that.dateInfo.push(item.name);
              that.countInfo.push(item.count);
            });
            }
            console.log( that.dateInfo,that.countInfo)
            that.drawLine();
          }
        });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("organization"));
      // 绘制图表
      myChart.setOption({
        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(84,126,202,.7)",
          },
          {
            offset: 0.5,
            color: "rgba(84,126,202,.4)",
          },
          {
            offset: 1,
            color: "rgba(84,126,202,.0)",
          },
        ]),
        tooltip: {
          //显示提示
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          top: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          splitLine: { show: false }, //关闭网格线
          axisLine: {
            //x轴坐标颜色
            lineStyle: {
              color: "rgba(255,255,255,.6)",
            },
          },
          data: this.dateInfo,
        },
        yAxis: {
          splitLine: { show: false },
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,.6)",
            },
          },
          type: "value",
        },
        series: [
          {
            name: "人次",
            type: "line",
            smooth: true,
            barCategoryGap: "70%",
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  textStyle: {
                    //数值样式
                    color: "#dfdfe1",
                    fontSize: 12,
                  },
                },
              },
            },
            areaStyle: {},
            data: this.countInfo,
          },
        ],
      });
    },
  },
};
</script>
<style scoped>
#organization {
  margin-top: 10px;
}
#organization > div {
  height: 88%;
  margin: 1vh 0;
  color: #01d1ff !important;
}
#organization > div > canvas {
  height: 88%;
  color: #01d1ff;
}
</style>