<template>
	<div>
		<div class="pie-dv-charts"><dv-charts style="height:19vh" :option="option" /></div>		
	</div>
</template>
<script>
export default {
	data () {
		return {
			option:{},
			data:{
				color:['#d54c5c','#f39801'],
				series: [
					{
					type: 'pie',
					data: [
						{ name: '默认', value: 0 },
						{ name: '默认', value: 0 },
					],
					insideLabel: {
						show: true,
					},
					outsideLabel:{
						show:false
					},
					radius:60
					}
				]
			}
		}
	},
	created () {
		let that = this;
		this.getInfo();
		setInterval(function(){
			that.getInfo();
		},60000)
	},
	methods: {
		getInfo(){
			let that = this;
			this.$utils.ajax('v1/school-user/user-gender-statistics').then((res)=>{
				if(res.status == 200){
					let data = that.data,totalNum = 0;
					res.data.forEach((item)=>{
						totalNum += item.userCount;
						if(item.gender == "男") {
							data.series[0].data[1].name = item.gender;
							data.series[0].data[1].value = item.userCount;
						}
						if(item.gender == "女") {
							data.series[0].data[0].name = item.gender;
							data.series[0].data[0].value = item.userCount;
						}
					})
					that.$store.commit('totalNum', totalNum)
					this.option = data;
				}
			})
		},
	}
}
</script>
<style scoped>
.pie-dv-charts{width:100%;height: 20vh;}
.pie-dv-charts canvas {
	width:100%;
	height: 20vh;
}
</style>