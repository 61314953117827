<template>
  <div class="content-left1">
    <div class="leftop">
      <div class="title-bg">学生活动动态统计</div>
      <activityChart />
    </div>
    <div class="leftop">
      <div class="title-bg">活动分类TOP5</div>
      <topChart />
    </div>
    <div class="leftop">
      <div class="title-bg">学生分布</div>
      <div >
        <div class="student-number-header font14 m-top10" v-if="$store.state.totalNum!=0">
          <div>
            <span class="block-style bg1"></span>男&nbsp;&nbsp;<span
              class="block-style bg2"
            ></span
            >女
          </div>
          <span
            >总人数：<span style="color: #c97c10; font-weight: 700">{{
              $store.state.totalNum
            }}</span>
          </span>
        </div>
        <div v-if="$store.state.totalNum==0" class="no-data1" style="right: 0.5em;bottom: 10px;">
        <img src="@/assets/backnone.png" alt="">
      </div>
        <div class="student-number-content"  >
          <div class="pie"><studentPieChart /></div>

          <div class="line"><studentLlineChart /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import activityChart from "./left/activityChart";
// import prizeChart from './left/prizeChart';
// import activateChart from './left/activateChart';
import topChart from "./right/topChart";
import studentPieChart from "./left/studentPieChart";
import studentLlineChart from "./left/studentLlineChart";
export default {
  components: {
    activityChart,
    studentPieChart,
    studentLlineChart,
    topChart,
  },
  data() {
    return {};
  },
  methods:{
       
 
  }
};
</script>
<style>
.content-left1 {
  width: 100%;
  height:100%;
}
.leftop {
  height:29.3vh;
  background: url(../assets/back.png) no-repeat;
  background-size: 100% 100%;
}
.prize-activate {
  display: flex;
  margin-top: 0px;
}
.prize,
.activate {
  width: 48%;
}
.activate {
  padding-left: 4%;
}
.student-number {
  width: 100%;
}
.student-number-content {
  display: flex;
  /* height:18.1vh !important; */
}
.student-number-header {
  width: 87%;
  display: flex;
  justify-content: space-between;
  padding: 0 5vh;
  line-height: 5.5vh;
}
.title-bg {
  color: #01e2ff;
  box-sizing: border-box;
  padding: 0 30px;
  font-weight: 700;
}
.label-column div {
  color: #6f808e;
}
.unit-label div {
  color: #6f808e;
}
.student-number-header {
  color: #647686 !important;
}
.student-number-header span {
  color: white !important;
  font-weight: 700;
}
.text-center {
  color: #647686 !important;
  font-size: 14px;
}
.pie {
  height: 200px;
  width: 200px;
}
.line {
  height: 200px;
  width: 300px;
}
.no-data1 { 
  height: 210px;
 display: flex;
    align-items: center;
    justify-content: center;
}
</style>
