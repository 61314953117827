import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../pages/home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'datav',
    redirect: '/datav',
    component: home
  },{
    path: '/datav',
    name: 'datav',
    component: home
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
