<template>
  <div id="myChart" :style="{ width: '100%', height: '89%' }"></div>
</template>
<script>
export default {
  name: "hello",
  data() {
    return {
      dateInfo: [],
      countInfo: [],
    };
  },
  created() {
    let that = this;
    this.getInfo();
    setInterval(function () {
      that.getInfo();
    }, 60000);
  },
  methods: {
    getInfo() {
      let that = this;
      this.$utils.ajax("v1/activity/activity-enroll-statistics").then((res) => {
        if (res.status == 200) {
          that.dateInfo = [];
          that.countInfo = [];
          res.data.forEach((item) => {
            that.dateInfo.push(item.data.split(" ")[0].slice(5));
            that.countInfo.push(item.count);
          });
          that.drawLine();
        }
      });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(0,186,255,.7)",
          },
          {
            offset: 0.5,
            color: "rgba(0,186,255,.5)",
          },
          {
            offset: 1,
            color: "rgba(0,186,255,.0)",
          },
        ]),
        tooltip: {
          //显示提示
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          top: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          splitLine: { show: false }, //关闭网格线
          axisLine: {
            //x轴坐标颜色
            lineStyle: {
              color: "rgba(96,199,173,.6)",
            },
          },
          data: this.dateInfo,
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#212c64"],
              width: 1,
              type: "solid",
            },
          },
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,.4)",
            },
          },
          type: "value",
        },
        series: [
          {
            name: "人次",
            type: "line",
            smooth: true,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  textStyle: {
                    //数值样式
                    color: "#dfdfe1",
                    fontSize: 12,
                  },
                },
              },
            },
            areaStyle: {},
            data: this.countInfo,
          },
        ],
      });
    },
  },
};
</script>
<style scoped> 
#myChart {
 margin-top: 10px;  
}
#myChart > div {
  height: 88%;
  margin: 1vh 0;
  color: #01d1ff !important;
}
#myChart > div > canvas {
  height: 88%;
  color: #01d1ff; 
}
</style>